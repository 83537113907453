#lowSignalOverlay{
    padding: 1.3vh 0;
    background: rgba(46, 56, 67, 0.9);
    position: fixed;
    left: 0;
    width: 100%;
    top: 5vh;   
    z-index: 5; 
}
#lowSignalText{
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vmax;
    line-height: 2.7vmax;
    text-align: center;
    color: #E9EDF0;
    font-family: Lato;
}
#lowSignalText>:nth-child(1){
    font-weight: 700;
}
#lowSignalText>:nth-child(2){
    font-size: 1.75vmax;
}
#lowSignalText img{
    width: 2.1vmax;
    margin-right: 0.8vmax;
    vertical-align: bottom;    
    filter: invert(61%) sepia(75%) saturate(715%) hue-rotate(342deg) brightness(100%) contrast(101%);
}
