html {
    height: 100%;
    width: 100%;
    font-size: 1.7vmin;
    line-height: 1.2;
}
body {
    height: 100%;
    width: 100%;
    padding: 0;
    font-size: 1.7vmin;
    margin: 0;
    background: black;
    font-family: "Helvetica Neue", arial, sans-serif;
    font-weight: 400;
    color: white;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

.bold {
    font-weight: bold;
}
.bolder {
    font-weight: 800;
}
.font-700{
    font-weight: 700;
}
.font-600{
    font-weight: 600;
}
.font-500{
    font-weight: 500;
}
.font-400{
    font-weight: 400;
}
#app {
    height: 100%;
}
.section {
    position: relative;
    margin-top: 12vh;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
    height: 86vh;
    width: 96vw;
}

/*****  SCROLL STYLING *****/
/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  }

  .scrollbarVisible {
    overflow-y: scroll;
  }
  .scrollbarVisible::-webkit-scrollbar {
    width: 0.2vw !important;
  }
  .scrollbarVisible::-webkit-scrollbar-thumb {
    background: #F5F6F8; 
    border-radius: 0.313vw;
  }

/* outline & border must use px , otherwise old TV browser doesn't apply*/

.overflow-y-scroll {
    overflow-y: scroll;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overlay {
    background-color: rgba(0, 0, 0, 0.4);
}
.z-1 {
    z-index: 1;
}
.z-10 {
    z-index: 10;
}
.z-100 {
    z-index: 100;
}
.z-900 {
    z-index: 900;
}
.z-101 {
    z-index: 101;
}
.white {
    color: white;
}

.overflow-hidden {
    overflow: hidden;
}
.overflow-y-hidden {
    overflow-y: hidden;
}
.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.bg-black {
    background-color: black;
}
.bg-white {
    background-color: white;
}
.bg-gray-100 {
    background-color: #F5F6F8;
}
.bg-gray-200 {
    background-color: #E9EDF0;
}
.bg-gray-300 {
    background-color: #D3DAE1;
}
.bg-gray-400 {
    background-color: #BDC8D3;
}
.bg-gray-600 {
    background-color: #91A3B5;
}
.bg-gray-800 {
    background-color: #576575;
}
.bg-gray-900 {
    background-color: #2E3843;
}
.fg-black {
    color: black;
}
.fg-white {
    color: white;
}

.bg-gray {
    background-color: gray;
}
.bg-gray-200 {
    background-color: #E9EDF0;
}
.bg-transparent {
    background-color: transparent !important;
}
.text-gray-100-important{
    color: #F5F6F8 !important;
}

.pre-line{
    white-space: pre-line;
}

.nowrap{
    white-space: nowrap;
}

.text-red-100{
    color: #DE350B;
}
.text-gray-100{
    color: #F5F6F8;
}
.text-gray-300{
    color: #D3DAE1;
}
.text-gray-600{
    color: #91A3B5;
}
.text-gray-800{
    color: #576575;
}
.text-gray-900{
    color: #2E3843;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}

.msgUrgentIcon{
    color: #FF991F;
}
.hidden{
    display: none;
}
.invisible {
    visibility: hidden;
}
.bg-contain {
    background-size: contain;
}
.bg-cover {
    background-size: cover;
}
.bg-cover-adjust {
    background-size: 100% 100%;
}
.bg-center {
    background-position: center;
}
.bg-no-repeat {
    background-repeat: no-repeat;
}

.content-center {
    justify-content: center;
}
.align-content-center {
    align-content: center;
}
.items-center {
    align-items: center;
}
.items-end {
    align-items: end;
    align-self: end;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.fixed {
    position: fixed;
}
.w-full {
    width: 100%;
}
.w-1px {
    width: 1px;
}
.w-10px {
    width: 10px;
}
.w-1\/2 {
    width: 50%;
}
.w-1\/3 {
    width: 33.333334%;
}
.w-2\/3 {
    width: 66.666664%;
}
.min-w-1\/2 {
    min-width: 50vw;
}
.min-w-1\/4 {
    min-width: 25vw;
}
.min-h-1\/2 {
    min-height: 50vw;
}
.min-h-1\/4 {
    min-height: 25vw;
}
.h-full {
    height: 100%;
}
.flex {
    display: flex;
}
.flex-flow-column {
    flex-flow: column;
}
.flex-flow-row {
    flex-flow: row;
}
.grid {
    display: grid;
}
.inline-block {
    display: inline-block;
}
.inline-flex {
    display: inline-flex;
}
.table {
    display: table;
}

.block {
    display: block;
}
.table-cell {
    display: table-cell;
}
.contents {
    display: contents;
}
.vertical-middle {
    vertical-align: middle;
}
.vertical-top {
    vertical-align: top;
}
.vertical-bottom {
    vertical-align: bottom;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.rounded-b {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.rounded-sm {
    border-radius: 0.15rem;
}
.rounded-lg {
    border-radius: 0.3rem;
}
.rounded-4vw{
    border-radius: 0.4vw;
}

.h-10rem {
    height: 10rem;
}
.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.left-0 {
    left: 0;
}
.right-0 {
    right: 0;
}
.border{	
    border-width: 1px;
    border-style: solid;
}
.border-0 {
    border: 0;
}
.border-gray-300{
    border-color: #D3DAE1;
}
.border-gray-400{
    border-color: #A7B5C4;
}

.h-auto {
    height: auto;
}
.w-auto {
    width: auto;
}
.h-1px {
    height: 1px;
}
.h-10px {
    height: 10px;
}
.h-1\/3 {
    height: 33.333334%;
}
.h-12 {
    height: 3rem;
}
.h-14 {
    height: 3.5rem;
}
.h-16 {
    height: 4rem;
}
.h-20 {
    height: 5rem;
}
.h-24 {
    height: 6rem;
}
.h-28 {
    height: 7rem;
}
.h-32 {
    height: 8rem;
}
.h-36 {
    height: 9rem;
}
.h-40 {
    height: 10rem;
}

.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}
.p-5 {
    padding: 1.25rem;
}
.p-6 {
    padding: 1.5rem;
}
.p-7 {
    padding: 1.75rem;
}
.p-8 {
    padding: 2rem;
}
.pr-1 {
    padding-right: 0.25rem;
}
.pr-2 {
    padding-right: 0.5rem;
}
.pr-3 {
    padding-right: 0.75rem;
}
.pr-4 {
    padding-right: 1rem;
}
.pr-5 {
    padding-right: 1.25rem;
}
.pr-6 {
    padding-right: 1.5rem;
}
.pr-7 {
    padding-right: 1.75rem;
}
.pr-8 {
    padding-right: 2rem;
}
.pl-1 {
    padding-left: 0.25rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-3 {
    padding-left: 0.75rem;
}
.pl-4 {
    padding-left: 1rem;
}
.pl-5 {
    padding-left: 1.25rem;
}
.pl-6 {
    padding-left: 1.5rem;
}
.pl-7 {
    padding-left: 1.75rem;
}
.pl-8 {
    padding-left: 2rem;
}
.pt-1\/2 {
    padding-top: 0.15rem;
}
.pt-1 {
    padding-top: 0.25rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-5 {
    padding-top: 1.25rem;
}
.pt-6 {
    padding-top: 1.5rem;
}
.pt-7 {
    padding-top: 1.75rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pb-1 {
    padding-bottom: 0.25rem;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-3 {
    padding-bottom: 0.75rem;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-5 {
    padding-bottom: 1.25rem;
}
.pb-6 {
    padding-bottom: 1.5rem;
}
.pb-7 {
    padding-bottom: 1.75rem;
}
.pb-8 {
    padding-bottom: 2rem;
}
.pl-4vw{
    padding-left: 4vw;
}


.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.m-1 {
    margin: 0.25rem;
}
.m-2 {
    margin: 0.5rem;
}
.m-3 {
    margin: 0.75rem;
}
.m-4 {
    margin: 1vw;
}
.m-5 {
    margin: 1.25rem;
}
.m-6 {
    margin: 1.5rem;
}
.m-7 {
    margin: 1.75rem;
}
.m-8 {
    margin: 2rem;
}
.mt-auto {
    margin-top: auto;
}
.mt-1 {
    margin-top: 0.25vh;
}
.mt-2 {
    margin-top: 0.5vh;
}
.mt-3 {
    margin-top: 0.75vh;
}
.mt-4 {
    margin-top: 1vh;
}
.mt-5 {
    margin-top: 1.25vh;
}
.mt-6 {
    margin-top: 1.5vh;
}
.mt-7 {
    margin-top: 1.75vh;
}
.mt-8 {
    margin-top: 2vh;
}
.mb-1 {
    margin-bottom: 0.25vh;
}
.mb-2 {
    margin-bottom: 0.5vh;
}
.mb-3 {
    margin-bottom: 0.75vh;
}
.mb-4 {
    margin-bottom: 1vh;
}
.mb-5 {
    margin-bottom: 1.25vh;
}
.mb-6 {
    margin-bottom: 1.5vh;
}
.mb-7 {
    margin-bottom: 1.75vh;
}
.mb-8 {
    margin-bottom: 2vh;
}
.mb-9 {
    margin-bottom: 2.25vh;
}
.mb-10 {
    margin-bottom: 2.5vh;
}
.mb-11 {
    margin-bottom: 2.75vh;
}
.mb-12 {
    margin-bottom: 3vh;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mr-3 {
    margin-right: 0.75rem;
}
.mr-4 {
    margin-right: 1rem;
}
.mr-5 {
    margin-right: 1.25rem;
}
.mr-6 {
    margin-right: 1.5rem;
}
.mr-7 {
    margin-right: 1.75rem;
}
.mr-8 {
    margin-right: 2rem;
}
.ml-1 {
    margin-left: 0.25rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-3 {
    margin-left: 0.75rem;
}
.ml-4 {
    margin-left: 1rem;
}
.ml-5 {
    margin-left: 1.25rem;
}
.ml-6 {
    margin-left: 1.5rem;
}
.ml-7 {
    margin-left: 1.75rem;
}
.ml-8 {
    margin-left: 2rem;
}

.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}
.mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}
.mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
}
.mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}
.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
}
.py-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}
.zoomOutAnimation{
    transform: scale(1.1);
}
.capitalize{
    text-transform: capitalize;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-1xl {
    font-size: 1.35rem;
    line-height: 1.75rem;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-4xl-2 {
    font-size: 2.5rem;
    line-height: 2.5rem;
}
.text-5xl {
    font-size: 3rem;
    line-height: 1;
}
.text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}
.text-ellipsis {
    text-overflow: ellipsis;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.mleft-auto {
    margin-left: auto;
}
.mright-auto {
    margin-right: auto;
}

/* TV */
.itemsList {
    position: absolute;
    right: 0;
    height: 75vh;
    width: 59vw;
    top: 3vw;
}
.itemsListFull {
    left: 3vw;
    top: 7vh;
    position: fixed;
    width: 94vw;
    height: 85vh;
    z-index: 100;
    visibility: visible;
}
.tvlogo {
    width: 9vw;
    height: 17.5vh;
}
.itemsListFull .tvlogo {
    width: 7vw;
    height: 11.5vh;
}

.channel {
    width: 9vw;
}
.itemsListFull .channel {
    width: 7vw;
}
.channelList{
    width: 100%;
    height: 100%;
    color:#FFFFFF;    
    background: radial-gradient(rgba(55, 61, 66,0.4), rgba(55, 61, 66,1));
    position:  fixed;
    top: 0;
    left:0;
    z-index: 100;
}
.channelList_no_gradient{
    background: rgba(55, 61, 66,0.6);
}
.channelList .filters{
    padding-top: 7vh;
}
.channelList .filters .filter span{
    width: 18vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}
.channelList .filters .filter{
    width: 18vw;
    padding: 0.7vw; 
    border-radius: 0.4vw;
}
.countryFilter,
.countryFilter span{
    width: 21vw !important;
}
#btn-continue{
    padding: 0.4vh;
    
}
.channelList-focus{
    background-color: #F5F6F8;
    color: #2E3843;
    font-weight: 700;
}

.continue-watching-focus,
.channel-focus .remark{
     box-shadow: inset 0 0 0 0.2vw #F5F6F8;
}
.grid-focus .remark{
    outline: 0.2vw solid #F5F6F8;
}
.channelList-focus .iconNameFocused,
#moviePlayerBar .iconNameFocused,
.channelInfo .iconNameFocused{
    float: right;
    font-size: 1.7vw;
    padding-left: 0.4vw;
    padding-right: 1.5vw;
    margin-top: -0.1vh;
}
.channelList-focus.icon{
    padding-left: 1vw;
    padding-right: 1vw;
    border-radius: 0.4vw;
}
#moviePlayerBar .iconActive,
.channelInfo .iconActive{
    padding-left: 1vw;
    padding-right: 1vw;
    border-bottom: 0.4vh solid white;
   
}
#moviePlayerBar .selector,
.channelInfo .selector{
    left: 0;
    bottom: 7.4vh;
    width: 100%;
    border-radius: 0.4vw;
    height: 12.8vh;
}
.channelFocusLeyend{
    height: 13vh;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0.9), transparent);
    padding-left: 10%;
    padding-right: 5%;
    margin-top: 0.4vh;
    padding-top: 2vh;
    overflow: hidden;
    display: none;
    font-weight: 400;
    font-size: 1.65vw;
}

.showHiddenLegend .channelFocusLeyend{
    display: block;
    visibility: hidden;
}

.borderBottomTransparent{
    border-bottom: 0.4vh solid transparent;
}

.verticalGroup .channel-focus .channelFocusLeyend,
.horizontalGroup .channel-focus .channelFocusLeyend_horizontal{
    display: block;
}
.horizontalGroup .grid-focus .channelFocusLeyend_horizontal{
    display: block;
    float: left;
}
.channelFocusLeyend_horizontal{
    height: 14vh;
    width: 18vw;
    background: linear-gradient(to right, rgba(0,0,0,0.9), transparent);
    padding-left: 4%;
    padding-right: 5%;
    margin-top: 0.4vh;
    padding-top: 2vh;
    overflow: hidden;
    display: none;
    font-weight: 400;
    font-size: 1.65vw;
}

#tv{
    color:#FFFFFF;
}

.channelDisplay{
    background: linear-gradient(to right, rgba(55, 61, 66,0.8), rgba(55, 61, 66,0));    
}
.channelInfo{
    background: linear-gradient(rgba(55, 61, 66,0), rgba(55, 61, 66,0.8));    
}
.channelList .bar, 
.channelInfo .bar{
    width: 100%;
    background: linear-gradient(to right, rgba(55, 61, 66,0.8), rgba(55, 61, 66,0));    
}
.channelInfo .bar > div{
    float: left;
}

#moviePlayerBar .icon, 
.channelInfo .icon{
    float: left;
    font-size:  3.3vw;
}
.selector .icon-chevron-up{
    top: 0.5vh;
    width: 100%;
    font-size: 2vw !important;
}
.selector .icon-chevron{
    top: 8.8vh;
    width: 100%;
    font-size: 2vw !important;
}


/* ANIMATIONS */

.animateMarginTop{
    -webkit-transition: margin-top 0.1s;
    -moz-transition: margin-top 0.1s;
    -o-transition: margin-top 0.1s;
    -ms-transition: margin-top 0.1s;
    transition: margin-top 0.1s;
}
.animateMarginLeft{
    -webkit-transition: margin-left 0.1s;
    -moz-transition: margin-left 0.1s;
    -o-transition: margin-left 0.1s;
    -ms-transition: margin-left 0.1s;
    transition: margin-left 0.1s;
}

.animateMarginLeftTickerTape{
    white-space: nowrap;
    animation: example1 20s linear infinite;
}

@keyframes scrolling {
    0% { transform: translateX(0) }
    100% { transform: translateX(-110%) }
}

@keyframes example1 {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: -110%;
    }
  }


.backgroundImageTransition{
    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
}

.fadeInAnimation-enter {
    opacity: 0;
    transform: scale(0);
  }
  .fadeInAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 600ms, transform 600ms;
  }
  .fadeInAnimation-exit {
    opacity: 1;
  }
  .fadeInAnimation-exit-active {
    opacity: 0;
    transition: opacity 600ms, transform 600ms;
  }
  #itemsList.fadeInAnimation-exit-active {
    opacity: 1;
    transition: opacity 0ms, transform 0ms;
  }

  .topAnimation-enter {
    margin-top: -100%;
  }
  .topAnimation-enter-active {
     margin-top: 0%;
    transform: translateX(0);
    transition:  margin-top 600ms, transform 600ms;
  }
  .topAnimation-exit {
     margin-top:0%;    
  }
  .topAnimation-exit-active {
    margin-top: -100%;
    transform: translateX(1);
    transition:  margin-top 600ms, transform 600ms;   
  }


  /* This fires as soon as the element enters the DOM */  
  .bottomAnimation-enter {
    bottom: 0%;
  }
  /* This is where we can add the transition*/
  .bottomAnimation-enter-active {
    bottom: 100%;
    transform: translateX(0);
    transition:  bottom 600ms, transform 600ms;
  }
  /* This fires as soon as the this.state.showList is false */
  .bottomAnimation-exit {
    bottom: 100%;
  }
  /* fires as element leaves the DOM*/
  .bottomAnimation-exit-active {
    bottom: 0%;
    transform: translateX(1);
    transition:  bottom 600ms, transform 600ms;
  }
  
  
  
  .leftAnimation-enter {
    margin-left: -100%;
    transform: scale(0);
  }
  .leftAnimation-enter-active {
     margin-left: 0%;
    transform: translateX(0);
    transition:  margin-left 600ms, transform 600ms;
  }
  .leftAnimation-exit {
     margin-left: 0%;
  }
  .leftAnimation-exit-active {
    margin-left: -100%;
    transition: margin-left 600ms, transform 600ms;
  }


  /* This fires as soon as the element enters the DOM */  
  .rightAnimation-enter {
    margin-left: 100%;
  }
  /* This is where we can add the transition*/
  .rightAnimation-enter-active {
     margin-left: 0%;
    transform: translateX(0);
    transition:  margin-left 600ms, transform 600ms;
  }
  /* This fires as soon as the this.state.showList is false */
  .rightAnimation-exit {
     margin-left: 0%;
  }
  /* fires as element leaves the DOM*/
  .rightAnimation-exit-active {
    margin-left: 100%;
    transition: margin-left 600ms, transform 600ms;
  }

  .heightAnimation {
    transition: height 0.65s ease-in;
  }
  .gridGroupAnimation {
    transition: all 0.1s ease-in;
  }
  #sales{
    display: block;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    /*position: fixed;
    display: inline-grid;*/
  }
  .LG #sales{
    position: initial;
    display: initial;
  }
  
  @-webkit-keyframes heartBeat {
    0% {
      transform: scale(1);
    }
    14% {
      transform: scale(1.3);
    }
    28% {
      transform: scale(1);
    }
    42% {
      transform: scale(1.3);
    }
    70% {
      transform: scale(1);
    }
  }
  @keyframes heartBeat {
    0% {
      transform: scale(1);
    }
    14% {
      transform: scale(1.3);
    }
    28% {
      transform: scale(1);
    }
    42% {
      transform: scale(1.3);
    }
    70% {
      transform: scale(1);
    }
  }
.lato{
    font-family: Lato;
}
.underSlashFocus{
    box-shadow: 0 0 0 0.26vw rgba(189,200,211)
}

.ribbon-wrapper-rented {
    width: 82.5%;
    height: 58%;
    overflow: hidden;
    position: absolute;
    top: 11.1vh;
    left: 1.75vw;
  }
  
  .ribbon-rented {
    font-size: 0.75em;
    color: #576575;
    text-align: center;
    text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
    -webkit-transform: rotate(-45deg);
    -moz-transform:    rotate(-45deg);
    -ms-transform:     rotate(-45deg);
    -o-transform:      rotate(-45deg);
    position: relative;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3vw;
    left: -6em;
    top: 5em;
    width: 20em;
    background-color: white;
  }
  
  .ribbon-rented:before, 
  .ribbon-rented:after {
    content: "";
    border-top:   3px solid #6e8900;   
    border-left:  3px solid transparent;
    border-right: 3px solid transparent;
    position:absolute;
    bottom: -3px;
  }
  
  .ribbon-rented:before {
    left: 0;
  }
  .ribbon-rented:after {
    right: 0;
  }
  .singleGridGroup {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(360deg, #292929 0%, rgba(41, 41, 41, 0.8) 61%, rgba(41, 41, 41, 0) 100%);
    background-color: black;
    color: #F5F6F8;
  }

  .sigleGroupPriceWrapper {
    width: 60%;
    height: 20%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(0,0,0,0.9), transparent);
    color: #F5F6F8
  }


.grid-btn-back-focus{
    background-color: #F5F6F8 !important;
    color: #2E3843 !important;
}

.readMoreFocus {
    color: #F5F6F8;
    background-color:transparent;
    border-bottom: 0.208117vw solid #F5F6F8 !important;    
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.borderTransparent {
    border-width: 0.390625vw;
    border-color: transparent !important;
    border-style: solid;
}

video::-webkit-media-text-track-display {
    position: absolute;
    width: 100%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    bottom: 12vh;
    font-size: 3.34vw;
}

::cue{
    font-size: 3.34vw;
    background: rgba(0,0,0,0);
}

.tickertape{
    position: absolute;
    width: 100%;
    display: inline-flex;
    align-items: center;
    z-index: 299;
}


@-webkit-keyframes bannerFadeIn {
    0% {
        transform: scale(0.1);
    }
    100% {
        transform: scale(1);
    }
  }
  @keyframes bannerFadeIn {
    0% {
        transform: scale(0.1);
    }    
    100% {
        transform: scale(1);
    }
  }
